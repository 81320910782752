import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { HeroImage, Intro, Category, Pagination, Section, SocialDesign } from "@components/Work"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Arrow } from "@components/UI"
import { splitText } from "@utils"
import IconographyDesktop from "@svg/work/ricoh/iconography-desktop.svg"
import IconographyTablet from "@svg/work/ricoh/iconography-tablet.svg"
import IconographyMobile from "@svg/work/ricoh/iconography-mobile.svg"
import workData from "@json/work.json"

const RicohPage = ({ data }) => {
  const {
    printOneDesktopImage,
    printOneTabletImage,
    printTwoDesktopImage,
    printTwoTabletImage,
    socialCardOneImage,
    socialCardTwoImage,
    socialCardThreeImage,
    socialScreenCaptureOneImage,
    socialScreenCaptureTwoImage,
    campaignOneImage,
    campaignTwoImage,
    photographyOneImage,
    photographyTwoImage,
    photographyThreeImage,
    photographyFourImage,
    galleryOneImage,
    galleryTwoImage,
    photographyBlurbDesktopImage,
    photographyBlurbTabletImage,
    photographyBlurbMobileImage,
  } = data
  const { name, headline, theme } = workData["ricoh"]
  const { themeColor } = usePageSetup({
    themeColor: theme.background,
    navigationColor: theme.navigationColor,
    displayGeneralForm: false,
  })

  return (
    <Layout>
      <Seo title={name} description={headline} />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1 className="tracking-normal text-white text-18">{name}</h1>
            <h2
              data-aos="stagger"
              className="text-work-headline mt-clamp-6-8"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": headline,
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <Arrow colorTop="bg-white" colorBottom="bg-white" fillColor="text-white" />

      <main className="overflow-hidden">
        <HeroImage client="ricoh" />
        <Category>Employer branding, Employee engagement, Workplace branding</Category>
        <Intro>
          <Intro.Overview>
            <Intro.Title>An employee engagement partnership built on understanding.</Intro.Title>
            <div className="mt-10">
              <p>
                Sometimes the greatest relationships start with a single project. That’s been the case with our friends
                at Ricoh, who initially engaged JK for workplace branding, an assignment that has led to a new employer
                brand, a year-long employee engagement campaign, and a great working partnership built on JK taking the
                time to truly understand Ricoh’s needs, their people goals, and their deep-seated values.
              </p>
            </div>
          </Intro.Overview>
          <Intro.Deliverables>
            <li>Employer branding</li>
            <li>EVP development</li>
            <li>Visual identity</li>
            <li>Messaging</li>
            <li>Workplace branding</li>
            <li>Brand strategy support</li>
            <li>Employee engagement</li>
            <li>Copywriting</li>
            <li>Art and graphic design</li>
          </Intro.Deliverables>
        </Intro>

        <section className="mt-clamp-34-29">
          <Section className="col-span-8 xl:col-span-7">
            <Section.Title>An employer brand built with purpose.</Section.Title>
            <Section.Description>
              <p>
                Ricoh has made a tremendous pivot as a business, shifting their focus from office printing solutions to
                enabling cutting-edge digital workplaces. Being successful in a time of such change requires keeping
                employees motivated and focused on what lies ahead while attracting the type of talent that will move
                their business forward.
              </p>
            </Section.Description>
          </Section>

          <div className="container relative mt-clamp-18-25">
            <Carousel>
              <CursorMarker>
                <CursorMarker.Print />
              </CursorMarker>
              <Carousel.Swiper
                breakpoints={{
                  1436: {
                    spaceBetween: 80,
                    slidesPerView: "auto",
                  },
                }}
              >
                <Carousel.Slide>
                  <div data-aos="fade-up">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printOneTabletImage)}
                          alt="Print 1"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printOneDesktopImage)}
                          alt="Print 1"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="200">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printTwoTabletImage)}
                          alt="Print 2"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printTwoDesktopImage)}
                          alt="Print 2"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
              </Carousel.Swiper>
            </Carousel>
          </div>
        </section>

        <section className="pt-clamp-22-48">
          <Section className="col-span-9 xl:col-span-8">
            <Section.Description>
              <p>
                Our employer brand platform “Powered By You” was designed to make each employee understand that they are
                a vital part of helping Ricoh make this important transition. It celebrates what each individual can do
                for themselves and for the company, putting them—and Ricoh—squarely at the center of what’s possible.
              </p>
            </Section.Description>
          </Section>
        </section>

        <section className="pt-40 sm:pt-16 lg:pt-40">
          <div className={`${themeColor} relative`}>
            <div className="absolute inset-0">
              <div className="container">
                <div className="aspect-w-667 aspect-h-339 lg:aspect-w-1436 lg:aspect-h-292 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
            </div>

            <SocialDesign>
              <SocialDesign.Card className="h-full">
                <GatsbyImage
                  objectFit="contain"
                  className="overflow-hidden shadow rounded-xl"
                  image={getImage(socialCardOneImage)}
                  alt="Social Card 1"
                />
              </SocialDesign.Card>
              <SocialDesign.Card className="sm:ml-auto lg:pt-24">
                <GatsbyImage
                  objectFit="contain"
                  className="overflow-hidden shadow rounded-xl"
                  image={getImage(socialCardTwoImage)}
                  alt="Social Card 1"
                />
              </SocialDesign.Card>
              <SocialDesign.Card className="h-full">
                <GatsbyImage
                  objectFit="contain"
                  className="overflow-hidden shadow rounded-xl"
                  image={getImage(socialCardThreeImage)}
                  alt="Social Card 2"
                />
              </SocialDesign.Card>
            </SocialDesign>

            <div className="container justify-center grid-cols-12 sm:flex lg:grid">
              <div className="w-full col-span-8 col-start-3 sm:w-11/12 lg:w-full pt-clamp-20-36">
                <div data-aos="fade-up">
                  <div className="display-desktop">
                    <IconographyDesktop />
                  </div>
                  <div className="display-tablet">
                    <IconographyTablet />
                  </div>
                  <div className="display-mobile">
                    <IconographyMobile />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${themeColor} relative pt-clamp-24-32`}>
            <div className="container">
              <div className="absolute inset-0 flex">
                <div className="w-full mt-auto aspect-w-1436 aspect-h-280 bg-whte-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <div className="grid-rows-3 lg:grid grid-cols-14">
                <div className="flex col-start-1 col-end-13 row-start-1 row-end-3">
                  <div data-aos="fade-up">
                    <GatsbyImage
                      className="overflow-hidden shadow rounded-xl"
                      objectFit="contain"
                      image={getImage(socialScreenCaptureOneImage)}
                      alt="Social screen capture 1"
                    />
                  </div>
                </div>
                <div className="flex col-start-5 row-start-2 row-end-4 mt-clamp-10-17 lg:mt-0 col-end-15">
                  <div className="w-full mt-auto">
                    <div data-aos="fade-up">
                      <GatsbyImage
                        className="overflow-hidden shadow rounded-xl"
                        objectFit="contain"
                        image={getImage(socialScreenCaptureTwoImage)}
                        alt="Social screen capture 2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-49-67">
          <Section className="col-span-10">
            <Section.Title>An engagement campaign that prepares employees for the future.</Section.Title>
            <Section.Description>
              <p>
                Our relationship with Ricoh continues to blossom as we develop concepts and craft a strategy for a
                year-long employee engagement campaign to help keep their people motivated, informed, and fully
                supported as the company forges a bold path into the future.
              </p>
            </Section.Description>
          </Section>
          <div className="pb-clamp-22-32" style={{ background: "#11809D" }}>
            <div className="container relative mt-clamp-21-25">
              <div className="absolute inset-0">
                <div className="w-full aspect-w-1436 aspect-h-246 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <div className="grid-rows-3 lg:grid grid-cols-14">
                <div className="flex col-start-1 col-end-8 row-start-1 row-end-3">
                  <div className="w-5/6 lg:w-full">
                    <div data-aos="fade-up">
                      <GatsbyImage
                        className="w-full h-full overflow-hidden shadow rounded-xl"
                        objectFit="cover"
                        image={getImage(campaignOneImage)}
                        alt="Campaign 1"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex col-start-7 row-start-2 row-end-4 mt-clamp-10-17 lg:mt-0 col-end-15">
                  <div className="w-5/6 ml-auto lg:w-full">
                    <div data-aos="fade-up">
                      <GatsbyImage
                        className="w-full h-full overflow-hidden shadow rounded-xl"
                        objectFit="cover"
                        image={getImage(campaignTwoImage)}
                        alt="Campaign 2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-25-76">
          <Section className="col-span-10 xl:col-span-9">
            <Section.Title>Facility branding that motivates from coast to coast.</Section.Title>
            <Section.Description>
              <p>
                Ricoh wanted to help employees live their mission and vision every day, so we built a modular toolkit
                that put Ricoh’s values and appetite for innovation on the walls and in the halls of facilities across
                the country.
              </p>
            </Section.Description>
          </Section>
          <div className="container relative pt-clamp-12-14">
            <Carousel>
              <CursorMarker>
                <CursorMarker.Gallery />
              </CursorMarker>
              <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                <Carousel.Slide style={{ width: "auto" }}>
                  <div data-aos="fade-up" className="pointer-events-none w-76 h-92 md:w-138 md:h-138 lg:w-208 lg:h-150">
                    <GatsbyImage className="h-full" image={getImage(photographyOneImage)} alt="Photography 1" />
                  </div>
                </Carousel.Slide>
                <Carousel.Slide style={{ width: "auto" }}>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="200"
                    className="pointer-events-none w-76 h-92 md:w-110 md:h-138 lg:w-117 lg:h-150"
                  >
                    <GatsbyImage className="h-full" image={getImage(photographyTwoImage)} alt="Photography 2" />
                  </div>
                </Carousel.Slide>
                <Carousel.Slide style={{ width: "auto" }}>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="300"
                    className="pointer-events-none w-76 h-92 md:w-110 md:h-138 lg:w-117 lg:h-150"
                  >
                    <GatsbyImage className="h-full" image={getImage(photographyThreeImage)} alt="Photography 3" />
                  </div>
                </Carousel.Slide>
                <Carousel.Slide style={{ width: "auto" }}>
                  <div data-aos="fade-up" className="pointer-events-none w-76 h-92 md:w-138 md:h-138 lg:w-208 lg:h-150">
                    <GatsbyImage className="h-full" image={getImage(photographyFourImage)} alt="Photography 4" />
                  </div>
                </Carousel.Slide>
              </Carousel.Swiper>
            </Carousel>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-37-56">
          <div className="container relative">
            <div className="grid-work-ricoh-gallery gap-y-4 gap-x-4">
              <div className="flex justify-center ricoh-gallery-copy pb-clamp-16-18 lg:pb-0">
                <div className="sm:w-10/12 lg:ml-auto lg:w-5/6">
                  <div data-aos="fade-up">
                    <p>
                      Getting their workplace branding just right required us to take a deep dive into Ricoh’s brand,
                      their heritage, and their unique culture. We got to know them so well that JK was their first call
                      when it was time to create a new employer brand.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex ricoh-gallery-image-1">
                <div className="sm:w-5/6 lg:w-full">
                  <div data-aos="fade-up">
                    <GatsbyImage
                      objectFit="contain"
                      className="w-full h-full"
                      image={getImage(galleryOneImage)}
                      alt="Gallery 1"
                    />
                  </div>
                </div>
              </div>
              <div className="flex ricoh-gallery-image-2">
                <div className="w-full mt-auto sm:ml-auto sm:w-5/6 lg:w-full">
                  <div data-aos="fade-up">
                    <GatsbyImage
                      objectFit="contain"
                      className="w-full h-full"
                      image={getImage(galleryTwoImage)}
                      alt="Gallery 2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative mt-clamp-16-54 pb-clamp-20-42" style={{ background: "#795484" }}>
          <div className="absolute inset-0">
            <div className="w-full aspect-w-667 aspect-h-202 lg:aspect-w-1436 lg:aspect-h-120 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
          </div>
          <div className="container flex justify-center">
            <div data-aos="fade-up" className="w-full lg:w-10/12">
              <div className="relative aspect-w-325 aspect-h-392 md:aspect-w-667 md:aspect-h-570 lg:aspect-w-1920 lg:aspect-h-1080">
                <div className="display-desktop">
                  <GatsbyImage
                    className="w-full h-full"
                    objectFit="contain"
                    image={getImage(photographyBlurbDesktopImage)}
                    alt="Reception area."
                  />
                </div>
                <div className="display-tablet">
                  <GatsbyImage
                    className="w-full h-full"
                    objectFit="contain"
                    image={getImage(photographyBlurbTabletImage)}
                    alt="Reception area."
                  />
                </div>
                <div className="display-mobile">
                  <GatsbyImage
                    className="w-full h-full"
                    objectFit="contain"
                    image={getImage(photographyBlurbMobileImage)}
                    alt="Reception area."
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container grid-cols-6 sm:grid lg:grid-cols-12">
            <div className="col-span-5 lg:col-start-3 lg:col-span-6">
              <p data-aos="fade-up" className="text-white text-clamp-14-18 mt-7 pl-11 lg:pl-0">
                The reception area was the right place to make a bold first impression—from the striking diagonal desk
                design to vivid red walls with Ricoh’s signature “imagination balloon” corporate brand graphic.
              </p>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-50-68 pb-clamp-27-43">
          <div className="container flex justify-center">
            <div data-aos="fade-up" className="w-full xl:w-10/12">
              <Carousel.Quote client="ricoh" single={true}></Carousel.Quote>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />
        <Pagination client="ricoh" />
      </main>
    </Layout>
  )
}

export default RicohPage

export const ricohQuery = graphql`
  query RicohPageQuery {
    printOneDesktopImage: file(relativePath: { eq: "work/ricoh/print-01-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneTabletImage: file(relativePath: { eq: "work/ricoh/print-01-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoDesktopImage: file(relativePath: { eq: "work/ricoh/print-02-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoTabletImage: file(relativePath: { eq: "work/ricoh/print-02-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    socialCardOneImage: file(relativePath: { eq: "work/ricoh/social-card-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    socialCardTwoImage: file(relativePath: { eq: "work/ricoh/social-card-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    socialCardThreeImage: file(relativePath: { eq: "work/ricoh/social-card-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    socialScreenCaptureOneImage: file(relativePath: { eq: "work/ricoh/social-screen-capture-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    socialScreenCaptureTwoImage: file(relativePath: { eq: "work/ricoh/social-screen-capture-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    campaignOneImage: file(relativePath: { eq: "work/ricoh/campaign-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    campaignTwoImage: file(relativePath: { eq: "work/ricoh/campaign-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    photographyOneImage: file(relativePath: { eq: "work/ricoh/photography-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    photographyTwoImage: file(relativePath: { eq: "work/ricoh/photography-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    photographyThreeImage: file(relativePath: { eq: "work/ricoh/photography-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    photographyFourImage: file(relativePath: { eq: "work/ricoh/photography-04.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryOneImage: file(relativePath: { eq: "work/ricoh/gallery-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryTwoImage: file(relativePath: { eq: "work/ricoh/gallery-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    photographyBlurbDesktopImage: file(relativePath: { eq: "work/ricoh/photography-blurb-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    photographyBlurbTabletImage: file(relativePath: { eq: "work/ricoh/photography-blurb-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    photographyBlurbMobileImage: file(relativePath: { eq: "work/ricoh/photography-blurb-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
